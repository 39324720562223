import { useState } from "react";
import { Buffer } from 'buffer';
import {
  Addresses,
  SignedMessage,
  useYoursWallet,
  Utxo,
  Balance,
  YoursIcon,
  SendBsv,
  SendBsvResponse,
  SocialProfile,
} from "yours-wallet-provider";
import { YoursConnectButton } from "../components/YoursConnectButton";
import { log } from "console";

export const SamplePage = () => {
  const wallet = useYoursWallet();
  const [pubKey, setPubKey] = useState<string | undefined>();
  const [addresses, setAddresses] = useState<Addresses | undefined>();
  const [messageToSign, setMessageToSign] = useState<string>("");
  const [messageToWriteOnchain, setMessageToWriteOnchain] = useState<string>("");
  const [fileHash, setFileHash] = useState<string | undefined>();
  const [txid, setTxid] = useState<string | undefined>(); // Neuer Zustand für die TXID
  const [utxos, setUtxos] = useState<Utxo[] | undefined>();
  const [balance, setBalance] = useState<Balance | undefined>();
  const [socialProfile, setSocialProfile] = useState<SocialProfile | undefined>();
  const [signedMessage, setSignedMessage] = useState<SignedMessage | undefined>();
  const [hasPaid, setHasPaid] = useState<boolean>(false);

  const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  

  const handleConnect = async () => {
    if (!wallet.connect) {
      window.open("https://yours.org", "_blank");
      return;
/*************  ✨ Codeium Command ⭐  *************/
/******  f2144ead-15b4-4939-ad84-6c567bed7fda  *******/    }
  /**
   * Connects to the wallet and retrieves the public key. If the wallet does
   * not support connecting, it opens a new window to the Yours.org website.
   * After connecting, it retrieves the social profile, UTXOs, and balance.
   */
    const key = await wallet.connect();
    if (key) setPubKey(key);

    if (wallet) {
      await wait(100); // Wait for 1 second
      handleSocialProfile()
      handleGetUTXOs();
      handleBalance();
    }
  };

  const handleGetAddresses = async () => {
    const addrs = await wallet.getAddresses();
    if (addrs) setAddresses(addrs);
  };

  const handleSignMessage = async () => {
    if (!messageToSign) {
      alert("There was no message to sign!");
      return;
    }
    const signRes = await wallet.signMessage({ message: messageToSign });
    if (signRes) setSignedMessage(signRes);
  };

  const handleGetUTXOs = async () => {
    try {
      const utxos = await wallet.getPaymentUtxos();
      console.log(utxos);
      if (utxos) setUtxos(utxos);
    } catch (err) {
        console.log(err);
    }
  }; 

  const handleBalance = async () => {
    try {
      const balance = await wallet.getBalance();
      console.log(balance);
      setBalance(balance);
    } catch (err) {
        console.log(err);
    }
  }

  const handleSocialProfile = async () => {
    try {
      const social = await wallet.getSocialProfile();
      console.log(social);
      setSocialProfile(social);
    } catch (err) {
        console.log(err);
    }
  };

  
  const buyImage = async () => {
    const paymentParams: { satoshis: number; address?: string; data?: string[] }[] = [
      {
        satoshis: 20000,
        address: "13uDBzA9S1AHW71gQoKg56BbV2p8gG15qn",
      },
    ];
    try {
      const response = await wallet.sendBsv(paymentParams);
      if (response) {
        const { txid, rawtx } = response;
        console.log("txid:",txid);
        if (txid) {
          setTxid(txid); // Speichert die TXID im Zustand
          setHasPaid(true)
        }
      } else {
        console.error("No response received from sendBsv");
      }
    } catch (err) {
      console.error("Error sending BSV:", err);
    }
  }

  const sendingBSV = async () => {
    console.log(messageToWriteOnchain)
    if (!messageToWriteOnchain) {
      alert("There was no message to write to the chain!");
      return;
    }
    const data = messageToWriteOnchain.split(" ")
    const paymentParams: { satoshis: number; address?: string; data?: string[] }[] = [
      {
        data: data.map((d) => Buffer.from(d).toString('hex')),
        satoshis: 0
      },
      {
        satoshis: 10000,
        address: "13uDBzA9S1AHW71gQoKg56BbV2p8gG15qn",
      },
    ];

    try {
      const response = await wallet.sendBsv(paymentParams);
      if (response) {
        const { txid, rawtx } = response;
        console.log("txid:",txid);
        setTxid(txid); // Speichert die TXID im Zustand
        return response
      } else {
        console.error("No response received from sendBsv");
      }
    } catch (err) {
      console.error("Error sending BSV:", err);
    }
  }

  const handleFileHash = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const arrayBuffer = await file.arrayBuffer();
      const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      setFileHash(hashHex);
    }
  };

  // Funktion zum Setzen von fileHash als messageToWriteOnchain
  const useFileHashAsMessage = () => {
    if (fileHash) {
      setMessageToWriteOnchain(fileHash);
    }
  };
  
  return (
    <div className="App">
      <header className="App-header">
        {!pubKey && (
          <YoursConnectButton onClick={handleConnect} />
        )}
        {pubKey && (
          <>
            <h3>Willkommen {`${socialProfile?.displayName}`}</h3>
            <img width='150px' src={`${socialProfile?.avatar}`}></img>
            {!hasPaid ? (
              <><img width='100px' src="./q.png" />
              <button onClick={buyImage} style={{ margin: "1rem" }}>
                Buy Image
              </button></>
            ) : (
              // Show the image once the user has paid
              <img
                width='350px'
                src=".\sonnenaufgang.png"
                className=""
                style={{ margin: "1rem" }}
                alt="Sonnenaufgang"
              />
            )}

            <h4>Nachricht Signieren</h4>
            <input
              placeholder="Text..."
              value={messageToSign}
              onChange={(e) => setMessageToSign(e.target.value)}
            />
            <button onClick={handleSignMessage} style={{ margin: "1rem" }}>
              Sign Message
            </button>
            {signedMessage && (
            <p style={{ width: "80%", fontSize: "0.75rem", margin: "1rem" }}>
              Signed Message: {JSON.stringify(signedMessage)}
            </p>
            )}
            <h4>Get Addresses</h4>
            <p style={{ width: "80%", fontSize: "0.75rem", margin: "1rem" }}>
              PubKey: {JSON.stringify(pubKey)}
            </p>
            <button onClick={handleBalance} style={{ margin: "0.5rem" }}> 
              Refresh Balance 
            </button>
            {balance && (
              <>
                <p style={{ width: "80%", fontSize: "0.75rem", margin: "0.5rem" }}>
                  Balance: {balance.bsv} BSV | {balance.satoshis} Satoshis | {balance.usdInCents} USD-Cents
                </p>
              </>
            )}
            <button onClick={handleGetUTXOs} style={{ margin: "0.5rem" }}>
              Refresh UTXOs
            </button>
            {utxos && (
            <p style={{ width: "80%", fontSize: "0.75rem", margin: "0.5rem" }}>
              <ul>
                {utxos.map((utxo, index) => (
                  <li key={index} style={{ listStyleType: 'none' }}>
                    {`Satoshis: ${utxo.satoshis}, TxId:`} 
                    <a target="_blank" href={`https://whatsonchain.com/tx/${utxo.txid}`} rel="noopener noreferrer">
                      {utxo.txid}
                    </a>
                    {`, Vout: ${utxo.vout}`}
                  </li>
                ))}
              </ul>
            </p>
            )}
            <h4>Hash a File</h4>
            <input type="file" onChange={handleFileHash} />
            {fileHash && (
              <>
                <p style={{ width: "80%", fontSize: "0.75rem", margin: "1rem" }}>
                  File Hash (SHA-256): {fileHash}
                </p>
                <button onClick={useFileHashAsMessage} style={{ margin: "1rem" }}>
                  Use File Hash as Message
                </button>
              </>
            )}
            <h4>Write Data on chain</h4>
            <input
              placeholder="Text..."
              value={messageToWriteOnchain}
              onChange={(e) => setMessageToWriteOnchain(e.target.value)}
            />
            <button onClick={sendingBSV} style={{ margin: "1rem" }}>
              Send BSV
            </button>
            {txid && (
            <p style={{ width: "80%", fontSize: "0.75rem", margin: "1rem" }}>
              Transaction ID: <a target="_blank" href={`https://whatsonchain.com/tx/${txid}`}>{txid}</a>
            </p>
            )}
          </>
        )}
      </header>
    </div>
  );
};
